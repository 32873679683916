import {backUrl} from "../utils/backURL";
import Cookies from "js-cookie";

export const getMonthlyPayments = async (requesterId, token) => {
  const csrfToken = Cookies.get("csrfToken");
  const req = await fetch(`${backUrl}/recurringPayments/getPayments/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      "X-CSRF-TOKEN": csrfToken
    },
    body: JSON.stringify({
      requesterId
    })
  });
  const res = await req.json();
  return res;
};

export const getInvestments = async (requesterId, token) => {
  const req = await fetch(`${backUrl}/integratedInvestments/getAll`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({
      requesterId
    })
  });
  const res = await req.json();
  return res;
};

export const getRecurringPayment = async (
  requesterId,
  token,
  recurringPaymentId
) => {
  const req = await fetch(`${backUrl}/recurringPayments/getById`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({
      requesterId,
      recurringPaymentId
    })
  });
  const res = await req.json();
  return res;
};

export const getInvestment = async (requesterId, token, investmentId) => {
  const req = await fetch(`${backUrl}/integratedInvestments/getById`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({
      requesterId,
      investmentId
    })
  });
  const res = await req.json();
  return res;
};

export const updateRecurringPayment = async (
  requesterId,
  token,
  recurringPaymentId,
  updatedPayment
) => {
  const req = await fetch(`${backUrl}/recurringPayments/update`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({
      requesterId,
      recurringPaymentId,
      updatedPayment
    })
  });
  const res = await req.json();
  return res;
};

export const updateInvestment = async (
  requesterId,
  token,
  investmentId,
  updatedInfo
) => {
  const req = await fetch(`${backUrl}/integratedInvestments/update`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({
      requesterId,
      investmentId,
      updatedInfo
    })
  });
  const res = await req.json();
  return res;
};

export const createNewPayment = async (requesterId, token, newPayment) => {
  const req = await fetch(`${backUrl}/payments/create`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({
      requesterId,
      newPayment
    })
  });
  const res = await req.json();
  return res;
};

export const updatePayment = async (
  requesterId,
  token,
  paymentId,
  paymentToUpdate
) => {
  const req = await fetch(`${backUrl}/payments/update`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({
      requesterId,
      paymentId,
      paymentToUpdate
    })
  });
  const res = await req.json();
  return res;
};

export const getTpagaBalance = async (requesterId, token) => {
  const req = await fetch(`${backUrl}/tpaga/getMantisBalance`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({requesterId})
  });
  const res = await req.json();
  return res;
};

export const getTpagaProviders = async (token) => {
  const req = await fetch(`${backUrl}/tpaga/getServiceProviders`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  });
  const res = await req.json();
  return res;
};

export const queryTpagaBill = async (
  serviceProviderId,
  billReference,
  token
) => {
  const req = await fetch(`${backUrl}/tpaga/queryBill`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({serviceProviderId, billReference})
  });
  const res = await req.json();
  return res;
};

export const payTpagaBill = async (
  idempotencyToken,
  amount,
  utilityProvider,
  shortBillReference,
  paymentToken,
  paymentOrigin,
  token
) => {
  const req = await fetch(`${backUrl}/tpaga/payBill`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({
      idempotencyToken,
      amount,
      utilityProvider,
      shortBillReference,
      paymentToken,
      paymentOrigin
    })
  });
  const res = await req.json();
  return res;
};

export const getMantisParams = async (token) => {
  const req = await fetch(`${backUrl}/mantisParams/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  });
  const res = await req.json();
  return res;
};

export const updateMantisParams = async (token, requesterId, updatedParams) => {
  const req = await fetch(`${backUrl}/mantisParams/update`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({requesterId, updatedParams})
  });
  const res = await req.json();
  return res;
};

export const getIrisLinkRequests = async (token, requesterId) => {
  const req = await fetch(`${backUrl}/collectionSources/getIrisLinkRequests`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({requesterId})
  });
  const res = await req.json();
  return res;
};

export const setIrisLink = async (
  token,
  requesterId,
  collectionSourceId,
  irisLink,
  amount,
  name,
  cellPhone
) => {
  const req = await fetch(`${backUrl}/collectionSources/setIrisLink`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({
      requesterId,
      collectionSourceId,
      irisLink,
      amount,
      name,
      cellPhone
    })
  });
  const res = await req.json();
  return res;
};

export const getMoneyOnMandateComponents = async (token, requesterId) => {
  const req = await fetch(`${backUrl}/moneyOnMandateComponents/getAll`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({requesterId})
  });
  const res = await req.json();
  return res;
};

export const approveMoneyOnMandateComponent = async (
  token,
  requesterId,
  moneyOnMandateComponentId
) => {
  const req = await fetch(`${backUrl}/moneyOnMandateComponents/approve`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({requesterId, moneyOnMandateComponentId})
  });
  const res = await req.json();
  return res;
};

export const declineMoneyOnMandateComponent = async (
  token,
  requesterId,
  moneyOnMandateComponentId,
  deniedReason
) => {
  const req = await fetch(`${backUrl}/moneyOnMandateComponents/decline`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({
      requesterId,
      moneyOnMandateComponentId,
      deniedReason
    })
  });
  const res = await req.json();
  return res;
};

export const getDashboardData = async (token, requesterId) => {
  const req = await fetch(`${backUrl}/insights/getDashboardData`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({requesterId})
  });
  const res = await req.json();
  return res;
};

export const getBillingData = async (token, requesterId) => {
  const req = await fetch(`${backUrl}/insights/getBillingData`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({requesterId})
  });
  const res = await req.json();
  return res;
};

export const updateUser = async (token, userId, updatedFields, requesterId) => {
  const req = await fetch(`${backUrl}/users/adminUpdate`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({userId, updatedFields, requesterId})
  });
  const res = await req.json();
  return res;
};

export const getUserDashboardData = async (token, requesterId) => {
  const req = await fetch(`${backUrl}/insights/getUserDashboardData`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({requesterId})
  });
  const res = await req.json();
  return res;
};

export const searchUser = async (token, requesterId, searchQuery) => {
  const req = await fetch(`${backUrl}/backoffice/searchUser`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({requesterId, searchQuery})
  });
  const res = await req.json();
  return res;
};

export const getUserRecurringPayments = async (token, requesterId, userId) => {
  const req = await fetch(`${backUrl}/backoffice/getUserRecurringPayments`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({requesterId, userId})
  });
  const res = await req.json();
  return res;
};

export const getUserBalance = async (token, requesterId, userId) => {
  const req = await fetch(`${backUrl}/backoffice/getUserBalance`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({requesterId, userId})
  });
  const res = await req.json();
  return res;
};

export const getMantisFee = async (
  token,
  requesterId,
  paymentsToPay,
  userId
) => {
  const req = await fetch(`${backUrl}/backoffice/getMantisFee`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({requesterId, paymentsToPay, userId})
  });
  const res = await req.json();
  return res;
};

export const createMoneyOnMandate = async (
  token,
  requesterId,
  userId,
  amountPaidByUser,
  recurringPaymentIds,
  paymentsAmount,
  mantisFee
) => {
  const req = await fetch(`${backUrl}/backoffice/createMoneyOnMandate`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({
      requesterId,
      userId,
      amountPaidByUser,
      recurringPaymentIds,
      paymentsAmount,
      mantisFee
    })
  });
  const res = await req.json();
  return res;
};

export const getUserBalances = async (token, requesterId) => {
  const req = await fetch(`${backUrl}/backoffice/getUserBalances`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({requesterId})
  });
  const res = await req.json();
  return res;
};

export const getUserBalanceComponents = async (token, requesterId, userId) => {
  const req = await fetch(`${backUrl}/getUserBalanceComponents`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({requesterId, userId})
  });
  const res = await req.json();
  return res;
};

export const generateBalanceComponent = async (
  token,
  requesterId,
  userId,
  amount,
  type,
  description,
  sign
) => {
  const req = await fetch(`${backUrl}/generateBalanceComponent`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({
      requesterId,
      userId,
      amount,
      type,
      description,
      sign
    })
  });
  const res = await req.json();
  return res;
};

export const updateBalanceComponent = async (
  token,
  requesterId,
  balanceComponentId,
  updatedBalanceComponent,
  userId
) => {
  const req = await fetch(`${backUrl}/updateBalanceComponent`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({
      requesterId,
      balanceComponentId,
      updatedBalanceComponent,
      userId
    })
  });
  const res = await req.json();
  return res;
};

export const getStartcoConversations = async (token, requesterId) => {
  const req = await fetch(`${backUrl}/backoffice/startco/getConversations`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({requesterId})
  });
  const res = await req.json();
  return res;
};

export const switchStartcoConversationMode = async (
  token,
  requesterId,
  conversationId
) => {
  const req = await fetch(
    `${backUrl}/backoffice/startco/switchConversationMode`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({requesterId, conversationId})
    }
  );
  const res = await req.json();
  return res;
};

export const sendStartcoManualMessage = async (
  token,
  requesterId,
  conversationId,
  message
) => {
  const req = await fetch(`${backUrl}/backoffice/startco/sendManualMessage`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({requesterId, conversationId, message})
  });
  const res = await req.json();
  return res;
};

export const getStartcoConversation = async (
  token,
  requesterId,
  conversationId
) => {
  const req = await fetch(`${backUrl}/backoffice/startco/getConversation`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({requesterId, conversationId})
  });
  const res = await req.json();
  return res;
};

export const getMantisBusinesses = async (token, requesterId) => {
  const req = await fetch(
    `${backUrl}/backoffice/mantisBusiness/getBusinesses`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({requesterId})
    }
  );
  const res = await req.json();
  return res;
};

export const getMantisBusiness = async (
  token,
  requesterId,
  mantisBusinessId
) => {
  const req = await fetch(`${backUrl}/backoffice/mantisBusiness/getBusiness`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({requesterId, mantisBusinessId})
  });
  const res = await req.json();
  return res;
};

export const getAllPermissions = async (requesterId, token) => {
  const req = await fetch(`${backUrl}/backoffice/getAllPermissions`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({requesterId})
  });
  const res = await req.json();
  return res;
};

export const updateBusinessPermissions = async (
  requesterId,
  mantisBusinessId,
  permissions,
  token
) => {
  const req = await fetch(
    `${backUrl}/backoffice/mantisBusiness/updatePermissions`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({
        requesterId,
        mantisBusinessId,
        permissions
      })
    }
  );
  const res = await req.json();
  return res;
};

export const updateBusinessEnabledSections = async (
  requesterId,
  mantisBusinessId,
  sections,
  token
) => {
  const req = await fetch(
    `${backUrl}/backoffice/mantisBusiness/updateEnabledSections`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({
        requesterId,
        mantisBusinessId,
        sections
      })
    }
  );
  const res = await req.json();
  return res;
};

export const updateBusinessDahboardUrls = async (
  requesterId,
  mantisBusinessId,
  urlObjects,
  token
) => {
  const req = await fetch(
    `${backUrl}/backoffice/mantisBusiness/updateDashboardUrls`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({
        requesterId,
        mantisBusinessId,
        urls: urlObjects
      })
    }
  );
  const res = await req.json();
  return res;
};

export const updateMantisBusiness = async (
  requesterId,
  mantisBusinessId,
  updatedBusiness,
  token
) => {
  const req = await fetch(
    `${backUrl}/backoffice/mantisBusiness/updateBusiness`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({
        requesterId,
        mantisBusinessId,
        updatedBusiness
      })
    }
  );
  const res = await req.json();
  return res;
};

export const getMantisBusinessDocumentQueries = async (
  requesterId,
  mantisBusinessId,
  token
) => {
  const req = await fetch(
    `${backUrl}/backoffice/mantisBusiness/getMantisBusinessDocumentQueries`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({
        requesterId,
        mantisBusinessId
      })
    }
  );
  const res = await req.json();
  return res;
};
